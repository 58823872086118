import { FC } from "react";

import LogoIcon from "icons/logo.svg?react";
import ChatIcon from "icons/chat.svg?react";

import { Pages } from "constants/pages";
import Button from "basics/button";

import ErrorScreen from "components/error";

import globalClasses from "styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  error: Error;
  status?: number;
}

const ErrorBoundary: FC<Props> = () => (
  <>
    <header className={classes.header}>
      <div className={classes.headerContent}>
        <a href={Pages.home}>
          <LogoIcon className={classes.logoIcon} />
        </a>
        <a className={classes.supportLink} href="/">
          <ChatIcon />
          Contact Support
        </a>
      </div>
    </header>
    <main className={globalClasses.textedMain} data-fullscreen>
      <div className={classes.content}>
        <ErrorScreen>
          <Button
            className={classes.buttonLink}
            themeName="primary"
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try to reload
          </Button>
        </ErrorScreen>
      </div>
    </main>
  </>
);

export default ErrorBoundary;
