import { useEffect } from "react";
import { Links, Meta } from "@remix-run/react";
const Head = () => {
  useEffect(() => {
    // removing duplications of <meta />, <title /> and <links /> (except for <links rel="stylesheet" /> because of flashing effect) tags.
    // Deleting first ones
    const suspectedNodes = Array.from(document.head.childNodes).filter(
      node =>
        node.nodeName === "META" ||
        node.nodeName === "TITLE" ||
        (node.nodeName === "LINK" &&
          (node as HTMLElement).getAttribute("rel") !== "stylesheet"),
    );
    for (let i = suspectedNodes.length; i > 0; i--) {
      for (let j = i - 2; j >= 0; j--) {
        if (suspectedNodes[i - 1].isEqualNode(suspectedNodes[j])) {
          suspectedNodes[j].remove();
          break;
        }
      }
    }
  }, []);
  return (
    <>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
      />
      <Meta />
      <Links />
    </>
  );
};
export default Head;
