import { FC, ReactNode } from "react";
import joinClassNames from "classnames";

import ErrorIcon from "icons/error.svg?react";

import classes from "./styles/classes.module.scss";

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  className?: string;
}

const ErrorScreen: FC<Props> = ({
  children,
  className,
  title = "Error",
  subtitle = "It seems like an unknown error has occurred",
}) => (
  <div className={joinClassNames(classes.centerContainer, className)}>
    <ErrorIcon className={classes.errorIcon} />
    <div>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.description}>{subtitle}</div>
    </div>
    {children}
  </div>
);

export default ErrorScreen;
