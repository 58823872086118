import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "@remix-run/react";

import ThemedHeader from "./components/themed-header";

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (location.hash) {
      navigate({ hash: location.hash.slice(1) }, { replace: true });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <ThemedHeader themeName={isScrolled ? "primary" : "inverted"} />;
};

export default Header;
