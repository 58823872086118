import { useDeferredValue } from "react";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import joinClassNames from "classnames";
import { useHydrated } from "remix-utils/use-hydrated";

import LogoIcon from "icons/logo.svg?react";
import LogoMobileIcon from "icons/logo-mobile.svg?react";

import Button, {
  BlankButton,
  Sizes,
  Themes as ButtonThemes,
} from "basics/button";

import { Pages } from "constants/pages";
import { APP_URL } from "constants/domains";

import useWindowSizes from "hooks/use-window-sizes";

import { Themes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  themeName: Themes;
}

const ThemedHeader = ({ themeName }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    isMobileWidth,
    isMobileSmallWidth,
    isMobileMediumWidth,
    isTabletWidth,
  } = useWindowSizes();
  const isHydrated = useDeferredValue(useHydrated());

  const isPrimary =
    themeName === "primary" || (themeName === "inverted" && pathname !== "/");

  let buttonSize: Sizes = "lg";
  if (isMobileWidth) {
    buttonSize = "md";
  }
  if (isMobileSmallWidth) {
    buttonSize = "sm";
  }
  buttonSize = isHydrated ? buttonSize : "sm";

  let buttonTheme: ButtonThemes = "default";
  const isTabletSmaller = isTabletWidth || isMobileWidth;
  if (isTabletSmaller) {
    buttonTheme = "inverted";
  }
  if (isPrimary) {
    buttonTheme = "primary";
  }

  const linkClasses = joinClassNames(classes.link, {
    [classes.primaryPoints]: !isPrimary && isTabletSmaller,
  });
  const logoIconClasses = joinClassNames(classes.logoIcon, {
    [classes.primaryLogo]: isPrimary,
  });

  return (
    <header
      data-hydrated={isHydrated}
      className={joinClassNames(classes.wrapper, {
        [classes.primary]: themeName === "primary",
      })}
    >
      <div className={classes.contentWrapper}>
        <Link to={Pages.home} className={classes.logoLink}>
          {!isHydrated || isMobileSmallWidth || isMobileMediumWidth ? (
            <LogoMobileIcon className={logoIconClasses} />
          ) : (
            <LogoIcon className={logoIconClasses} />
          )}
          <span className={classes.logoText}>Coindisco</span>
        </Link>
        <div className={classes.linksWrapper}>
          <BlankButton
            className={linkClasses}
            onClick={() => {
              navigate(
                { pathname: "/", hash: "integration" },
                { replace: true },
              );
            }}
          >
            Integration
          </BlankButton>
          <Link to={Pages.points} className={linkClasses}>
            Points
          </Link>
        </div>
        <Button
          as="a"
          size={buttonSize}
          themeName={buttonTheme}
          target="_blank"
          href={APP_URL}
        >
          Open App
        </Button>
      </div>
    </header>
  );
};

export default ThemedHeader;
