import { FC, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useHydrated } from "remix-utils/use-hydrated";
import {
  Outlet,
  useRouteError,
  isRouteErrorResponse,
  ScrollRestoration,
  Scripts,
  useNavigation,
} from "@remix-run/react";
import { LinksFunction } from "@remix-run/cloudflare";

import defaultImage from "meta/default.png";

import { LineLoader } from "basics/loader";

import { PRODUCTION_DOMAIN } from "constants/domains";

import useNonce from "hooks/use-nonce";

import ErrorBoundaryScreen from "components/error-boundary";
import Header from "components/header";
import Footer from "components/footer";
import NotFound from "components/not-found";
import Dialog from "components/dialog";

import { createMeta } from "operations/meta";

import globalClasses from "styles/classes.module.scss";

import Head from "./Head";
import "./styles/global.scss";

export const Layout = ({ children }: PropsWithChildren) => {
  const { state } = useNavigation();
  const isHydrated = useHydrated();
  const nonce = useNonce();
  const content = (
    <>
      {state === "loading" && (
        <LineLoader className={globalClasses.lineLoader} />
      )}
      {children}
      <Footer />
      <Dialog />
      <ScrollRestoration nonce={nonce} />
      <Scripts nonce={nonce} />
    </>
  );
  if (typeof document === "undefined") {
    return (
      <html>
        <head>
          <Head />
        </head>
        <body>
          <div id="_remix">{content}</div>
        </body>
      </html>
    );
  }
  return (
    <>
      {isHydrated && createPortal(<Head />, document.head)}
      {content}
    </>
  );
};

const App: FC = () => (
  <>
    <Header />
    <Outlet />
  </>
);

export const links: LinksFunction = () => [
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: `${PRODUCTION_DOMAIN}/assets/favicon.png`,
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: `${PRODUCTION_DOMAIN}/assets/apple-touch-icon.png`,
  },
  { rel: "stylesheet", href: "/fonts/fonts.css" },
];

export const meta = createMeta(({ error }) => {
  let title = "Coindisco | The Best Way To Buy Crypto";
  if (isRouteErrorResponse(error)) {
    title = error.status === 404 ? "404 Not found" : "Error occurred";
  }

  return {
    isRoot: true,
    title,
    description:
      "The best way to buy crypto. Compare top on/off-ramp providers and buy crypto in the fastest and most cost-effective way.",
    ogImage: defaultImage,
  };
});

export const ErrorBoundary: FC = () => {
  const error = useRouteError();
  // eslint-disable-next-line no-console
  console.error(JSON.stringify(error));

  return isRouteErrorResponse(error) && error.status === 404 ? (
    <>
      <Header />
      <NotFound />
    </>
  ) : (
    <ErrorBoundaryScreen error={error as Error} />
  );
};

export default App;
